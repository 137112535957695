<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加项目</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <div class="flex gap-4">
          <en-scrollbar :height="height" view-class="w-60 bg-gray-100 p-4 flex flex-col gap-4">
            <div
              v-for="item of category.data"
              @click="category.item.click(item)"
              :class="['rounded-1 cursor-pointer text-sm p-2', { 'bg-primary text-white': category.current?.id === item.id }]"
            >
              <span>{{ item.name }}</span>
            </div>
          </en-scrollbar>
          <div class="flex-1 overflow-auto">
            <table-dynamic
              code="MTNFD"
              :data="table.data"
              :height="height"
              :loading="table.loading"
              :method="table.get"
              :config="table.config"
              pagination
              :paging="table.paging"
            >
              <template #NAME="{ row }: { row: EnocloudCommonDefinitions['MaintenanceDto'] }">
                <en-button type="primary" text @click="table.name.click(row)">{{ row.name }}</en-button>
              </template>
            </table-dynamic>
          </div>
        </div>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '维修项目'" direction="btt" size="80%">
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-5 gap-x-6"
    >
      <en-form-item label="类别名称" prop="category.id">
        <select-maintain
          v-model="detail.form.data.category"
          :ajax="{
            action: 'GET /enocloud/common/maintenance/category',
            params: (params) => (params.payload = { pagingEnabled: false })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="项目名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="计价方式">
        <select-maintain
          v-model="detail.form.data.valuationMethod"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['VLMTD']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="项目编号">
        <en-input :model-value="detail.form.data.serialNo" disabled placeholder="项目编号"></en-input>
      </en-form-item>
      <en-form-item label="助记符">
        <en-input :model-value="detail.form.data.nameHint" disabled placeholder="助记符"></en-input>
      </en-form-item>
      <en-form-item label="项目成本">
        <en-input v-model="detail.form.data.defaultCost"></en-input>
      </en-form-item>
    </en-form>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="detail.form.data.chargingStandards" :loading="detail.form.loading" :height="height">
          <en-table-column label="车品牌类别名称">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              {{ $index === 0 ? '基础收费' : row.vehicleType?.name }}
            </template>
          </en-table-column>
          <en-table-column label="维修工时">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              <en-input-number
                v-model="row.laborHour"
                :min="0"
                :step="1"
                @change="detail.form.chargingStandards.laborHour.change($index, row)"
              ></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="按工时计价收费">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              <en-input-number
                v-model="row.unitPrice"
                :min="0"
                :step="1"
                @change="detail.form.chargingStandards.unitPrice.change($index, row)"
              ></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="按金额计价收费">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              <en-input-number
                v-model="row.fixedPrice"
                :min="0"
                :step="1"
                @change="detail.form.chargingStandards.fixedPrice.change($index, row)"
              ></en-input-number>
            </template>
          </en-table-column>
          <en-table-column label="上下浮类型">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              <span v-if="!$index">--</span>
              <span v-else>{{ row.vehicleType?.adjustingValue && row.vehicleType?.adjustingValue > 0 ? '上浮' : '下浮' }}</span>
            </template>
          </en-table-column>
          <en-table-column label="对照基础浮动类型">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              <span v-if="!$index">--</span>
              <span v-else>{{ row.vehicleType?.adjustingMethod?.message }}</span>
            </template>
          </en-table-column>
          <en-table-column label="上下浮比例">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              <span v-if="!$index">--</span>
              <span v-else>
                {{ row.vehicleType?.adjustingMethod?.code === 'P' ? `${(Math.abs(row.vehicleType?.adjustingValue ?? 0) * 100).toFixed(2)}%` : '--' }}
              </span>
            </template>
          </en-table-column>
          <en-table-column label="上下浮金额">
            <template #default="{ row, $index }: { row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto'], $index: number }">
              <span v-if="!$index">--</span>
              <span v-else>{{ row.vehicleType?.adjustingMethod?.code === 'F' ? row.vehicleType?.adjustingValue?.toFixed(2) : '--' }}</span>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button :disabled="detail.form.loading" @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click" :disabled="detail.form.loading">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          async click() {
            this.detail.form.init()
            this.detail.form.data.valuationMethod = { code: this.store.attribute.SVMTVLM.value, ...this.store.attribute.SVMTVLM }
            this.detail.form.data.unitPrice = this.detail.form.data.valuationMethod.code === 'H' ? Number(this.store.attribute.SVMTVLM.value) : 0
            this.detail.form.data.chargingStandards = [
              this.detail.form.data,
              ...this.detail.vehicle.data.map((item) => {
                return {
                  unitPrice: 0,
                  fixedPrice: 0,
                  vehicleType: { ...item },
                  laborHour: 1
                }
              })
            ]
            this.detail.visible = true
          }
        }
      },
      category: {
        current: null as EnocloudCommonDefinitions['MaintenanceCategoryDto'] | null,
        ajax: {
          get: {
            action: 'GET /enocloud/common/maintenance/category',
            data: 'array',
            params(params) {
              params.payload = { pagingEnabled: false }
            }
          }
        },
        children: {
          item: {
            click(row: EnocloudCommonDefinitions['MaintenanceCategoryDto']) {
              this.category.current = row
              this.table.getByTree()
            }
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/maintenance',
            data: 'array',
            pagination: true,
            loading: true
          },
          getByTree: {
            action: 'GET /enocloud/common/maintenance/category/:maintenanceCategoryId/maintenances',
            data: 'array',
            pagination: true,
            loading: true,
            params(params) {
              params.paths = [this.category.current?.id]
            }
          }
        },
        config: {
          NAME: { header: { filter: { type: 'text', field: 'name' } } },
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } }
        },
        children: {
          name: {
            async click(row: EnocloudCommonDefinitions['MaintenanceDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              await this.detail.form.get()
              this.detail.form.data.chargingStandards.unshift(Object.assign({}, this.detail.form.data))
              this.detail.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          vehicle: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/type',
                data: 'array'
              }
            }
          },
          form: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/maintenance/:maintenanceId',
                data: 'object',
                loading: true,
                init: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/maintenance',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/maintenance',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              'category.id': [{ required: true, message: '请选择类别' }],
              name: [{ required: true, message: '请输入项目名称' }]
            },
            children: {
              chargingStandards: {
                laborHour: {
                  change(index: number, row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto']) {
                    if (!index) {
                      this.detail.form.data.laborHour = row.laborHour
                    }
                  }
                },
                unitPrice: {
                  change(index: number, row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto']) {
                    if (!index) {
                      this.detail.form.data.unitPrice = row.unitPrice
                    }
                  }
                },
                fixedPrice: {
                  change(index: number, row: EnocloudCommonDefinitions['MaintenanceVehicleTypeDto']) {
                    if (!index) {
                      this.detail.form.data.fixedPrice = row.fixedPrice
                    }
                  }
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                this.detail.form.data.chargingStandards = this.detail.form.data.chargingStandards.slice(1)
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                if (this.category.current?.id) {
                  return this.table.getByTree().then(() => (this.detail.visible = false))
                } else {
                  return this.table.get().then(() => (this.detail.visible = false))
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.category.get()
    this.table.get()
    this.detail.vehicle.get()
  }
})
</script>
